import '@assets/scss/pages/project.scss';

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// components
import Layout from '@src/layouts';
import Video from '@components/atoms/Video';
import Blockquote from '@components/molecules/Blockquote';
import ButtonSecondary from '@components/atoms/buttons/ButtonSecondary';
import Column from '@components/organisms/Column';
import Gallery from '@components/organisms/Gallery';
import HeroProject from '@components/molecules/heros/HeroProject';
import Image from '@components/atoms/Image';
import ListText from '@components/organisms/ListText';

// --------------------------------
// #region data
// --------------------------------

const language = 'en';

const pageClass = 'page-project';

const accent_color = '#F0140A';
const footerRelatedLinks = [
	{
		title: 'ECA Vaud',
		url: '/en/projects/eca-vaud/',
	},
];

// --------------------------------
// #endregion
// --------------------------------

const ProjectFwtBrandingPage = (props) => {
	const data = useStaticQuery(
		graphql`
			query {
				heroImage: file(
					relativePath: {
						eq: "projects/fwt/branding/fwt_branding_001.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				heroImageMobile: file(
					relativePath: {
						eq: "projects/fwt/branding/fwt_branding_001_mobile.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1000, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_1: file(
					relativePath: {
						eq: "projects/fwt/branding/fwt_branding_002.png"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_2: file(
					relativePath: {
						eq: "projects/fwt/branding/fwt_branding_003.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_3: file(
					relativePath: {
						eq: "projects/fwt/branding/fwt_branding_004.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_4: file(
					relativePath: {
						eq: "projects/fwt/branding/fwt_branding_005.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_5: file(
					relativePath: {
						eq: "projects/fwt/branding/fwt_branding_006.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_6: file(
					relativePath: {
						eq: "projects/fwt/branding/fwt_branding_008.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_6_mobile: file(
					relativePath: {
						eq: "projects/fwt/branding/fwt_branding_008_mobile.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_7: file(
					relativePath: {
						eq: "projects/fwt/branding/fwt_branding_009.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_8: file(
					relativePath: {
						eq: "projects/fwt/branding/fwt_branding_010.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_9: file(
					relativePath: {
						eq: "projects/fwt/branding/fwt_branding_011.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_10: file(
					relativePath: {
						eq: "projects/fwt/branding/fwt_branding_014.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_11: file(
					relativePath: {
						eq: "projects/fwt/branding/fwt_branding_016.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_12: file(
					relativePath: {
						eq: "projects/fwt/branding/fwt_branding_017.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_12_mobile: file(
					relativePath: {
						eq: "projects/fwt/branding/fwt_branding_017_mobile.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1000, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_13: file(
					relativePath: {
						eq: "projects/fwt/website/fwt_siteweb_007.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	);

	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			accentColor={accent_color}
			location={props.location}
			translations={[
				{
					uri: '/fr/projets/fwt-freeride-world-tour-branding/',
					lang: 'fr',
				},
			]}
			title="Rebranding Freeride World Tour- Par Superhuit"
		>
			<HeroProject
				breadcrumb={{
					title: 'Work',
					link: '/en/projects/',
				}}
				title="Freeride World Tour Rebranding – FWT"
				image={{
					...data.heroImage.childImageSharp,
				}}
				imageMobile={{
					...data.heroImageMobile.childImageSharp,
				}}
			/>
			<section className="grid grid-content" aria-label="Projet">
				<div className="project-intro" data-animation-page>
					<div className="project-summary text-content">
						<p className="p-medium">
							Translate the quintessential freedom, adrenalin and
							visceral force of freeride into the new identity.
						</p>

						<ListText
							className="element-spacing"
							title="Nos services"
							list={[
								'Strategy',
								'Workshops',
								'Brand design',
								'Poster',
							]}
						/>
					</div>
					<div className="project-description text-content">
						<h3>The challenge</h3>
						<p>
							The Freeride World Tour is the freeride world
							championship that manages the worldwide
							Professional, Qualifier, Challenger and Junior
							Freeride Competitions. The organization commissioned
							us to overhaul the competition's visual identity,
							with a view to opening a new chapter for the years
							to come. At the same time, we were asked to propose
							a brand architecture that would make sense across
							the different competition categories.
						</p>

						<h3>Our response</h3>
						<p>
							Through a series of workshops, we clarified the
							Freeride World Tour brand positioning for today and
							tomorrow. This formed the base on which a new visual
							identity was proposed, with an approach designed to
							result in brand greater consistency and strength
							across the different seasons.
						</p>
						<p>
							The work also includes an overhaul of the Freeride
							World Tour logo and the creation of new logos to
							match the evolution of the competition name.
						</p>

						<hr />
						<h3>Find out more</h3>
						<ButtonSecondary
							href="/en/projects/fwt-freeride-world-tour/"
							text="Discover the FWT website redesign"
							data-target
							className="-red"
						/>
					</div>
				</div>

				<div className="box text-content">
					<div className="list-two-columns">
						<div className="text-content">
							<h3 style={{ color: accent_color }}>Brand DNA</h3>
							<p>
								Brand DNA is the fundamental framework within
								which the brand operates, providing both a
								creative and strategic framework for the brand's
								evolution. It is defined through creative
								workshops.
							</p>
						</div>
						<Image
							{...data.image_1.childImageSharp}
							alt="Brand DNA"
						/>
					</div>
				</div>

				<Image
					{...data.image_2.childImageSharp}
					alt="Logo"
					className="box col-full"
				/>

				<Column modifiers="right">
					<div className="column__text column__sticky text-content">
						<h3>A new name, a new logo</h3>
						<p>
							As the 2024 season gets underway, the Freeride World
							Tour is changing its name to FWT. This change is
							designed to reinforce consistency and improve
							differentiation between the various categories of
							competition.
						</p>
						<p>
							The intention of the FWT logo is to create a strong
							brand, drawing on the distinctive features of the
							Freeride World Tour logo while retaining a distinct
							identity.
						</p>
						<p>
							A new range of color codes completes the distinction
							between each competition category.
						</p>
					</div>
					<div className="column__media text-content">
						<Image {...data.image_3.childImageSharp} alt="Colors" />
						<Image
							{...data.image_4.childImageSharp}
							alt="Competions' logos"
							className="box"
						/>
					</div>
				</Column>

				<Column modifiers="right">
					<div className="column__text column__sticky text-content">
						<h3>Freeride World Tour logo facelift and revision</h3>
						<p>
							The Freeride World Tour logo, which will be used in
							a variety of contexts, has been redesigned to allow
							for enhanced maturity and legibility. The harmony of
							its lettering and graphic forms has been
							meticulously redesigned.
						</p>
					</div>
					<div className="column__media text-content">
						<Image
							{...data.image_5.childImageSharp}
							alt="Logo modification"
							className="box box--no-padding"
						/>
						<Video
							fluid={[
								{
									src: '11606f055360009dcec4dc6fba1038e0',
								},
								{
									src: 'ca138740514768729d035f40a42616d9',
									minWidth: 768,
								},
							]}
							alt="Freeride World Tour - Animation Logo"
							className="box col-full box--no-padding"
						/>
					</div>
				</Column>

				<Video
					fluid={[
						{
							src: '1fbbbb7de5e1e07af3b22e3f173c9653',
						},
						{
							src: 'aee16ecdf7b5e537a1961cc2103a7a0f',
							minWidth: 768,
						},
					]}
					className="col-full"
				/>

				<div className="box text-content">
					<h3>
						Respect the mountain, both its magnitude and its
						temperament.
						<br />
						Dare to attack it while striving to draw the perfect
						line.
					</h3>
					<div className="list-two-columns grid-spacing">
						<div className="text-content">
							<p>
								Freeride symbolizes the quintessence of freedom
								- the perfection of a line on pristine,
								untouched mountains.
							</p>
							<p>
								The new visual identity draws its inspiration
								from this essence. It reveals a streamlined
								structure, marked by empty spaces and majestic
								mountain images.
							</p>
						</div>
						<div className="text-content">
							<p>
								This is directly juxtaposed by graphic assets
								evoking audacity to set the brand’s identity
								apart from the crowd. This audacity is defined
								by defying the immaculate in order to draw the
								supreme line. It's the adrenaline and visceral
								force of freeriding that comes through in an
								identity with a powerful signature, reinforced
								by bold graphic codes.
							</p>
						</div>
					</div>
				</div>

				<Image
					alt="Mountain"
					className="col-full"
					fluid={[
						data.image_6_mobile.childImageSharp.fluid,
						{
							...data.image_6.childImageSharp.fluid,
							media: `(min-width: 768px)`,
						},
					]}
				/>

				<Gallery className="col-full grid-spacing" modifiers="gutter">
					<Image {...data.image_7.childImageSharp} alt="Ski" />
					<Image
						{...data.image_8.childImageSharp}
						alt="Mobile FWT website"
					/>
				</Gallery>

				<Image
					{...data.image_9.childImageSharp}
					className="col-full grid-spacing"
				/>

				<Video
					fluid={[
						{
							src: '60174ab339cff899f4a4e1855a943059',
						},
						{
							src: 'd1236d9debd6c949313eca8cc9ab786d',
							minWidth: 768,
						},
					]}
					className="col-full grid-spacing"
				/>

				<Video
					src="764cee63ab806865857f541b725f39ff"
					className="col-full grid-spacing"
				/>

				<Blockquote
					modifiers="big"
					uptitle="Customer testimonial"
					quote="Our collaboration was seamless and responsive for the duration of the brand and web project. Superhuit managed to take into account our strategic challenges and business constraints to provide us with strong solutions."
					more={
						<>
							<span className="text-content">
								Superhuit was a true strategic partner
								throughout the project, from branding to website
								redesign. Their thoughts and opinions remained
								objective and focused on the objectives we had
								set, but with a constant eye out for where
								improvements could be made.
							</span>
							<span className="text-content">
								Throughout all the meetings, workshops,
								countless emails and phone calls, we were able
								to establish a real relationship of trust with
								the whole team!
							</span>
						</>
					}
					cite="Gabriel Wicki – Head of communication"
				/>

				<Gallery className="col-full" modifiers="gutter">
					<Image {...data.image_10.childImageSharp} alt="Ski" />
					<Image
						{...data.image_11.childImageSharp}
						alt="FWT website on mobile"
					/>
				</Gallery>

				<Image
					alt="Posters"
					className="col-full grid-spacing box box--no-padding"
					fluid={[
						data.image_12_mobile.childImageSharp.fluid,
						{
							...data.image_12.childImageSharp.fluid,
							media: `(min-width: 768px)`,
						},
					]}
				/>

				<Column modifiers="right">
					<div className="column__text column__sticky text-content">
						<h3>Redesign of the Freeride World Tour website</h3>
						<p>
							The organization called on superhuit to redesign its
							website to better distinguish the different
							competitions and their data, while generating
							transversality to increase awareness of the
							Challenger and Junior Competitions.
						</p>
						<ButtonSecondary
							href="/en/projects/fwt-freeride-world-tour/"
							text="Discover the FWT website redesign"
							data-target
							className="-red small-spacing"
						/>
					</div>
					<div className="column__media text-content">
						<Image
							{...data.image_13.childImageSharp}
							alt="Site Internet FWT"
						/>
					</div>
				</Column>
			</section>
		</Layout>
	);
};

export default ProjectFwtBrandingPage;
